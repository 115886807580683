import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';

import Env from 'Helpers/environment';
import { initGA, logEvent } from 'analytics';
import { FiltersProvider, UrlContext } from 'context';
import BatchGalleryPage from 'pages/BatchGalleryPage';
import ContentDetailPage from 'pages/ContentDetailPage';
import GalleryPage from 'pages/GalleryPage';
import PersonaPage from 'pages/PersonaPage';
import SearchPage from 'pages/SearchPage';

import './App.css';
import PrivacyPage from 'pages/PrivacyPage';
import ContactPage from 'pages/ContactPage';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const App = () => {
  const location = window.location.pathname;
  const [url, setUrl] = useState('');

  try {
    if (Env.CurrentEnv === Env.Prod) {
      initGA();
    }
  } catch (e) {
    console.log('Error initializing google analytics', e);
  }

  const RedirectToContent = () => {
    const { videoUrl } = useParams();
    return <Navigate to={`/content/${encodeURIComponent(videoUrl)}`} />;
  };

  useEffect(() => {
    try {
      if (Env.CurrentEnv === Env.Prod) {
        if (location.startsWith('/video')) {
          const videoUrl = decodeURIComponent(location.split('/video/')[1]);
          console.log('Video URL', videoUrl);
          logEvent('Navigation', 'Referred via video link', videoUrl);
        } else if (location === '/') {
          logEvent('Navigation', 'Direct visit to video brand safety');
        }
      }
    } catch (e) {
      console.log('Error logging event', e);
    }
  }, [location]);

  const isPersonaEnabled = Env.PersonaEnvs.includes(Env.CurrentEnv);
  const isSearchEnabled = Env.SearchEnvs.includes(Env.CurrentEnv);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UrlContext.Provider value={{ url, setUrl }}>
          <FiltersProvider>
            <Routes>
              <Route path="/" element={<GalleryPage />} />
              <Route path="content/:videoUrl" element={<ContentDetailPage />} />
              <Route path="video/:videoUrl" element={<RedirectToContent />} />
              <Route path="batch/:batchId" element={<BatchGalleryPage />} />
              {isPersonaEnabled && <Route path="persona/" element={<PersonaPage />} />}
              {isSearchEnabled && <Route path="search/" element={<SearchPage />} />}
              <Route path="privacy/" element={<PrivacyPage />} />
              <Route path="contact/" element={<ContactPage />} />
            </Routes>
          </FiltersProvider>
        </UrlContext.Provider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
