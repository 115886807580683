// Inspired by: https://codepen.io/elliempatten/pen/KxmoGR
import React, { useState, useEffect } from 'react';
import './CardFlip.scss';
import MobiusStripPlaceholder from '../../assets/mobius-strip.webp';
import { useSwipeable } from 'react-swipeable';

const CardFlip = ({ imageSrc, mainContent, title, backContent }) => {
  const [flipped, setFlipped] = useState(false);
  const [showImage, setShowImage] = useState(true); // setShowImage being false means show placeholder image

  const flip = () => {
    setFlipped(!flipped);
  };

  const handlers = useSwipeable({
    onSwipedLeft: flip,
    onSwipedRight: flip,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isValidImageSrc = (imageSrc) => {
    if (!imageSrc) return false;
    const imageSrcString = String(imageSrc);
    if (imageSrcString.startsWith('s3://')) return false; // Ignore S3 URLs
    return isValidUrl(imageSrcString);
  };

  const handleImageLoad = () => {
    setShowImage(true);
  };

  const handleImageError = () => {
    setShowImage(false);
  };

  useEffect(() => {
    if (isValidImageSrc(imageSrc)) {
      const img = new Image();
      img.src = imageSrc; // Attempt to load the image with the provided source
      img.onload = handleImageLoad;
      img.onerror = handleImageError;
    } else {
      setShowImage(false); // Immediately hide the image if the source is invalid
    }
  }, [imageSrc]);

  return (
    <div
      {...handlers}
      onMouseEnter={flip}
      onMouseLeave={flip}
      className={`card-container${flipped ? ' flipped' : ''}`}
    >
      <div className="front">
        <ImageArea imageSrc={imageSrc} showImage={showImage} mainContent={mainContent} />
        <MainArea mainContent={mainContent} title={title} />
      </div>
      <div className="back">
        <BackArea content={backContent} />
      </div>
    </div>
  );
};

const ImageArea = ({ imageSrc, showImage, mainContent }) => (
  <div className="image-container">
    {showImage ? (
      <img className="card-image" src={imageSrc} alt={`thumbnail for ${mainContent}`} />
    ) : (
      <img
        className="card-image placeholder-card-image"
        src={MobiusStripPlaceholder}
        alt="placeholder"
      />
    )}
  </div>
);

const MainArea = ({ mainContent, title }) => (
  <div className="main-area">
    <div className="poster-content">{mainContent}</div>
    <div className="title">{title}</div>
  </div>
);

const BackArea = ({ content }) => (
  <div className="main-area">
    <div className="back-content">{content}</div>
  </div>
);

export default CardFlip;
