import styled from '@emotion/styled';

const Input = styled.input`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export default Input;
