import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { ChartCellContent } from '../Chart/ChartCell'; // Import your existing ChartCellContent component

const ChartAndStatsCell = ({
  title,
  chartData,
  topStats,
  totalCount,
  contentCount,
  orientation = 'horizontal',
  reverse = false,
  description,
  sx,
  titlePosition = 'top',
  descriptionPosition = 'bottom',
  onDataClick,
}) => {
  const isVertical = orientation === 'vertical';
  const displayOrder = reverse ? ['stats', 'chart'] : ['chart', 'stats'];
  const titleOrder = titlePosition === 'top' ? ['title', 'content'] : ['content', 'title'];
  const descriptionOrder =
    descriptionPosition === 'top' ? ['description', 'chart'] : ['chart', 'description'];

  return (
    <Grid
      item
      sx={{
        border: '1px solid #E7E7E7',
        textAlign: 'center',
        padding: 2,
        backgroundColor: '#000',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ...sx,
      }}
    >
      {titleOrder.map((item, index) =>
        item === 'title' ? (
          <Typography key={index} variant="h4">
            {title}
          </Typography>
        ) : (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: '1rem',
              flex: 1,
            }}
          >
            {descriptionOrder.map((item, index) =>
              item === 'chart' ? (
                <Box
                  key={index}
                  sx={{ width: '100%', flex: 1, marginTop: '10px', marginBottom: '10px' }}
                >
                  <ChartCellContent chartData={chartData} onDataClick={onDataClick} />
                </Box>
              ) : (
                <Box key={index} sx={{ width: '100%', flex: 1 }}>
                  {description ? (
                    <Typography variant="body1">{description}</Typography>
                  ) : (
                    <Typography variant="body1" display="block">
                      Mobian detected{' '}
                      <Box component="span" fontWeight="fontWeightBold">
                        {totalCount}
                      </Box>{' '}
                      unique {title.toLowerCase()}s.
                    </Typography>
                  )}
                </Box>
              ),
            )}
          </Box>
        ),
      )}
    </Grid>
  );
};

export default ChartAndStatsCell;
