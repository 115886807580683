import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Box, Chip, FormControl, MenuItem, Select, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { FiltersContext, Filter, FilterOption } from '../context/FiltersContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sortAlphabetically = (a: Filter, b: Filter) => a.label.localeCompare(b.label);
const uniqueFilters = (arr: Filter[]) => arr.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
const processFilters = (filters: Filter[]) => uniqueFilters(filters).sort(sortAlphabetically);

const SearchFilterDropdown: React.FC<FilterOption> = ({
  title,
  options: initialOptions,
  values,
  setter,
  displaySetter = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lastPage } = useContext(FiltersContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [options, setOptions] = useState<Filter[]>(processFilters([...initialOptions, ...values]));

  const triggerPage = location.pathname || '/';

  const handleFilterChange = (newValues: Filter[]) => {
    setter(newValues);
    if (displaySetter) {
      displaySetter(newValues);
    }
    if (!triggerPage.startsWith('/batch') && triggerPage !== '/') {
      navigate(lastPage, { state: { filtersUpdated: true, from: triggerPage } });
    }
    setIsDropdownOpen(false);
  };

  const handleAddFilter = (valuesToAdd: string[]) => {
    const newValues = options.filter((o) => valuesToAdd.includes(o.value));
    handleFilterChange(newValues);
  };

  const handleRemoveFilter = (valueToRemove: string) => {
    const newValues = values.filter((v) => v.value !== valueToRemove);
    handleFilterChange(newValues);
  };

  useEffect(() => {
    setOptions((prevOptions) => processFilters([...prevOptions, ...values]));
  }, [initialOptions, values]);

  if (!(values || options)) {
    return null;
  }

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <StyledSelect
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          displayEmpty
          value={values.map((v) => v.value)}
          open={isDropdownOpen}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          onChange={(e) => handleAddFilter(e.target.value as string[])}
          renderValue={(selected: string[]) => (
            <Box sx={{ gap: 0.5, display: 'flex', flexWrap: 'wrap' }}>
              {selected.length === 0 && <PlaceholderText>{title}</PlaceholderText>}
              {selected.map((value) => (
                <FilterChip
                  key={value}
                  value={value}
                  options={options}
                  handleRemoveFilter={handleRemoveFilter}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default SearchFilterDropdown;

interface FilterChipProps {
  value: string;
  options: Filter[];
  handleRemoveFilter: (value: string) => void;
}

const FilterChip: React.FC<FilterChipProps> = ({ value, options, handleRemoveFilter }) => {
  const option = options.filter((o) => o.value === value).pop();
  if (!option) return null;
  return (
    <StyledChip
      key={option.value}
      label={option.label}
      deleteIcon={<Close />}
      onMouseDown={(e: React.MouseEvent) => {
        e.stopPropagation(); // prevents Select from intercepting
      }}
      onDelete={(e: React.MouseEvent) => {
        e.stopPropagation();
        handleRemoveFilter(value);
      }}
    />
  );
};

const StyledSelect = styled(Select)`
  background-color: white;
  min-height: 40px;
  padding: 0;

  & .MuiSelect-select {
    padding: 4px 8px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
  }

  @media (max-width: 600px) {
    & .MuiSelect-icon {
      display: none;
      width: 0px;
      height: 0px;
    }

    & .MuiSelect-select {
      font-size: 0px;
      padding: 2px 4px;
    }

    #demo-multiple-chip {
      padding-right: 2px;
    }

    & .MuiBox-root {
      gap: 2px;
    }
  }
`;

const StyledChip = styled(Chip)`
  background-color: #263238;
  color: white;
  border-radius: 8px;
  margin: 1px;
  padding: 0px 0px;
  max-width: 100%;

  & .MuiChip-deleteIcon {
    color: white;
    &:hover {
      color: #ccc;
    }
  }

  & .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (max-width: 600px) {
    font-size: 8px;
    & .MuiChip-deleteIcon {
      font-size: 14px;
      margin: 0 2px 0 -2px;
    }
    & .MuiChip-label {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
`;

const PlaceholderText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: gray;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 8px;
  }
`;
