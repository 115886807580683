import React from 'react';
import { Box, Typography } from '@mui/material';

const ContentHighlightItem = ({ description, videoId, start, source }) => {
  return (
    <div>
      {source === 'youtube' ? (
        <a
          className="collapsible-content dropdown-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://youtube.com/watch?v=${videoId}&t=${start}ms`}
        >
          <Typography fontSize="14px" color="#607D8B">
            {description}
          </Typography>
        </a>
      ) : (
        <Typography className="collapsible-content" fontSize="14px" color="#607D8B">
          {description}
        </Typography>
      )}
    </div>
  );
};

const AdditionalContext = ({ videoId, highlights, source }) => {
  return (
    highlights?.length > 0 && (
      <Box paddingTop="12px">
        <Box>
          <Typography fontSize="16px" fontWeight="600" paddingTop="8px">
            Additional Context
          </Typography>
          {highlights.map((highlight, index) => (
            <ContentHighlightItem
              key={index}
              description={highlight.description}
              videoId={videoId}
              start={highlight.start}
              source={source}
            />
          ))}
        </Box>
      </Box>
    )
  );
};

export default AdditionalContext;
