// Hits the persona API (AWS ECS Fargate service running in main Mobian AWS)
// https://github.com/montauklabs/persona-api
import { Persona, ApiResponse, PersonaSearchResponse, PersonaWebsiteDetail } from './types';
import Env from 'Helpers/environment';

// FIXME: Add key/url to "secrets" file for consistency.
// Not actually a secret though (loaded into client browser)
const apiKey = 'jtKWUBw9TEHGGm84OwcFKmpr6WZPOAfKhFIicOTwKJmWZUSw';

const PROD_URL = 'https://persona-prod.mobianx.com';
const STAGING_URL = 'https://persona-staging.mobianx.com';
const API_ENV_MAP = {
  [Env.Prod]: PROD_URL,
  [Env.IrisProd]: PROD_URL,
  [Env.Staging]: PROD_URL,
  [Env.Test]: STAGING_URL,
  [Env.IrisTest]: STAGING_URL,
  // Local dev, hit local endpoints (persona API only)
  [Env.Dev]: 'http://localhost:8001',
};

const baseUrl = API_ENV_MAP[Env.CurrentEnv] || API_ENV_MAP[Env.Prod];

export const personaApi = {
  personaDetail: async (personaId: number): Promise<ApiResponse<Persona>> => {
    const url = `${baseUrl}/api/persona/${personaId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  webpageDetail: async (url: string): Promise<ApiResponse<PersonaWebsiteDetail>> => {
    const params = { url: url };
    const qs = new URLSearchParams(params).toString();
    const requestUrl = `${baseUrl}/api/webpage/detail?${qs}`;
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  searchQuery: async (
    query: string,
    page: number | null,
    pageSize: number,
    domain?: string,
    languageCode?: string,
  ): Promise<ApiResponse<PersonaSearchResponse>> => {
    const params = {
      query: query,
      page_size: pageSize.toString(),
      page: (page || 1).toString(),
    };
    if (domain) {
      params['domain'] = domain;
    }
    if (languageCode) {
      params['language_code'] = languageCode;
    }
    const qs = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/webpage?${qs}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  searchPersona: async (
    personaId: number | string,
    domain?: string,
    language?: string,
  ): Promise<ApiResponse<PersonaSearchResponse>> => {
    const params = {
      persona_id: personaId.toString(),
    };
    if (domain) {
      params['domain'] = domain;
    }
    if (language) {
      params['language_code'] = language;
    }
    const qs = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/webpage?${qs}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  create: async (prompt: string): Promise<ApiResponse<Persona>> => {
    const response = await fetch(`${baseUrl}/api/persona`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
      body: JSON.stringify({ prompt }),
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  update: async (personaId: number, prompt: string): Promise<ApiResponse<Persona>> => {
    const response = await fetch(`${baseUrl}/api/persona`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
      body: JSON.stringify({ id: personaId, prompt }),
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },

  get: async (personaId: number | string): Promise<ApiResponse<Persona>> => {
    const response = await fetch(`${baseUrl}/api/persona/${personaId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    const json = await response.json();
    return {
      status: response.status,
      body: json,
    };
  },
  deleteTopic: async (topicId: number): Promise<ApiResponse<null>> => {
    const response = await fetch(`${baseUrl}/api/topic/${topicId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Public-API-Key': apiKey,
      },
    });
    return {
      status: response.status,
      body: null,
    };
  },
};
