import { useState, useEffect, useRef } from 'react';

import api, { Persona } from 'api';

const usePersonaCreate = (
  persona: Persona | null,
  setPersona: (persona: Persona | null) => void,
  personaId: number | null,
  setPersonaId: (id: number | null) => void,
  message: string,
  setMessage: (string) => void,

  onSuccess: () => void,
) => {
  const initialFetchPerformed = useRef(false);
  const [isPersonaBuilding, setIsPersonaBuilding] = useState(false);
  const [isPersonaCreateError, setIsPersonaCreateError] = useState(false);

  const fetchPersona = async (personaId: number) => {
    try {
      const resp = await api.persona.get(personaId);
      if (resp.status === 200) {
        const persona = resp.body;
        setPersona(persona);
        setMessage(persona.prompt);
        setPersonaId(persona.meta.id);
        onSuccess();
      } else {
        console.error('Could not fetch persona:', resp.status);
        setPersonaId(null);
      }
    } catch (error) {
      console.error('Error fetching persona:', error);
      setPersonaId(null);
    }
  };

  useEffect(() => {
    const shouldFetchPersona = personaId && !persona && !initialFetchPerformed.current;
    if (!shouldFetchPersona) return;
    initialFetchPerformed.current = true;
    fetchPersona(personaId);
  }, [personaId, persona]);

  const handleSendMessage = async () => {
    if (message.trim() === '') return;
    setIsPersonaBuilding(true);
    setIsPersonaCreateError(false);
    try {
      let resp;
      if (persona) {
        resp = await api.persona.update(persona.meta.id, message);
      } else {
        resp = await api.persona.create(message);
      }
      const newPersona = resp.body;
      setPersona(newPersona);
      setPersonaId(newPersona.meta.id);
      setIsPersonaBuilding(false);
      onSuccess();
    } catch (error) {
      console.error('Error generating persona:', error);
      setIsPersonaCreateError(true);
      setIsPersonaBuilding(false);
    }
  };

  return {
    message,
    setMessage,
    handleSendMessage,
    persona,
    state: {
      loading: isPersonaBuilding,
      error: isPersonaCreateError,
    },
  };
};

export default usePersonaCreate;
