import React, { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { Box, Typography } from '@mui/material';
import LowRiskGauge from '../../assets/low_risk_gauge.svg';
import MediumRiskGauge from '../../assets/medium_risk_gauge.svg';
import HighRiskGauge from '../../assets/high_risk_gauge.svg';
import LoadingRiskGauge from '../../assets/loading_risk_gauge.svg';

const RiskGauge = ({ riskLevel }) => {
  const [riskLabel, setRiskLabel] = useState(null);
  const [riskGaugeImage, setRiskGaugeImage] = useState(null);
  const [riskLabelColor, setRiskLabelColor] = useState('#A9A9A9');
  const [gaugeValue, setGaugeValue] = useState(0.0);

  const getSentimentDetails = (riskLevel) => {
    switch (riskLevel) {
      case 'Low Risk':
        setRiskLabel('Low');
        setRiskGaugeImage(LowRiskGauge);
        setRiskLabelColor('#4CAF50');
        //setGaugeValue(0.15);
        break;
      case 'Medium Risk':
        setRiskLabel('Medium');
        setRiskGaugeImage(MediumRiskGauge);
        setRiskLabelColor('#FF9800');
        //setGaugeValue(0.50);
        break;
      case 'High Risk':
        setRiskLabel('High');
        setRiskGaugeImage(HighRiskGauge);
        setRiskLabelColor('#F44336');
        //setGaugeValue(0.90);
        break;
      default:
        setRiskLabel(null);
        setRiskGaugeImage(LoadingRiskGauge);
        setRiskLabelColor('#ECEFF1');
      //setGaugeValue(0.0);
    }
  };

  useEffect(() => {
    getSentimentDetails(riskLevel);
  }, [riskLevel]);

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '90%',
      }}
    >
      {riskGaugeImage && (
        <img
          src={riskGaugeImage}
          alt="thumbnail"
          style={{ width: '100%', height: '100%', margin: '2px' }}
        ></img>
      )}
    </Box>
  );
};

export default RiskGauge;
