import { useNavigate, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import Env from 'Helpers/environment';
import { FiltersContext, UrlContext } from 'context';
import MobianLogo from 'logo.svg';
import SearchBar from './SearchBar';

interface PageProps {
  children: React.ReactNode;
  greyBackground?: boolean;
  customBackground?: boolean;
  customBackgroundColor?: string;
  showSearch?: boolean;
  title?: string;
}

const Page: React.FC<PageProps> = ({
  children,
  greyBackground = false,
  customBackground = false,
  customBackgroundColor = '',
  showSearch = false,
  title = 'Mobian',
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUrl } = useContext(UrlContext);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);
  const {
    setRedirection,
    clearFilters,
  } = useContext(FiltersContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (location.state && location.state.from) {
      setRedirection(location.state.from);
    }
  }, [location.state, setRedirection]);

  const handleClearAllFilters = () => {
    clearFilters();
  };

  const handleLogoClick = () => {
    handleClearAllFilters();
    setUrl('');
    navigate('/');
  };

  return (
    <PageContainer
      greyBackground={greyBackground}
      customBackground={customBackground}
      customBackgroundColor={customBackgroundColor}
    >
      <HeaderContainer
        greyBackground={greyBackground}
        customBackground={customBackground}
        customBackgroundColor={customBackgroundColor}
      >
        <Header>
          <Logo src={MobianLogo} alt="Mobian logo" onClick={handleLogoClick} />
          {process.env.REACT_APP_ENV !== 'production' && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsNavMenuOpen(!isNavMenuOpen)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Header>
        <NavMenu isOpen={isNavMenuOpen} setIsOpen={setIsNavMenuOpen} />
        {showSearch && <SearchBar handleClearAllFilters={handleClearAllFilters} />}
      </HeaderContainer>
      <AppContainer>{children}</AppContainer>
    </PageContainer>
  );
};

export default Page;

const PageContainer = styled.div<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) =>
    props.customBackground
      ? props.customBackgroundColor
      : props.greyBackground
        ? '#f5f5f5'
        : 'white'};
  position: relative;
`;

const HeaderContainer = styled.header<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--page-padding);
  ${(props) => props.greyBackground && `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);`}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0.25em;
  height: var(--header-height);
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 18px;
  cursor: pointer;
`;

const AppContainer = styled.div`
  padding: 0 var(--page-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 70%;
  flex: 1;
`;

interface NavMenuProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const NavMenu: React.FC<NavMenuProps> = ({ isOpen, setIsOpen }) => {
  const isPersonaEnabled = Env.PersonaEnvs.includes(Env.CurrentEnv);
  const isSearchEnabled = Env.SearchEnvs.includes(Env.CurrentEnv);

  const navigate = useNavigate();

  const handleNavClick = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <NavMenuContainer isOpen={isOpen}>
      <NavMenuInner isOpen={isOpen}>
        <NavItem onClick={() => handleNavClick('/')}>Gallery</NavItem>
        {isPersonaEnabled && <NavItem onClick={() => handleNavClick('/persona')}>Personas</NavItem>}
        {isSearchEnabled && <NavItem onClick={() => handleNavClick('/search')}>Search</NavItem>}
      </NavMenuInner>
    </NavMenuContainer>
  );
};

const NavItem = styled.div`
  cursor: pointer;
  padding: 0.5em 1em;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const NavMenuContainer = styled.div<{ isOpen: boolean }>`
  height: ${(props) => (props.isOpen ? 'var(--header-height)' : '0')};
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavMenuInner = styled.div<{ isOpen: boolean }>`
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  transition-delay: ${(props) => (props.isOpen ? '0.3s' : '0s')};
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    font-size: 0.8em;
  }
`;
