import { Grid, Typography } from '@mui/material';

import { ComparisonChart } from './ComparisonChart';

export function ChartCellContent({ chartData, onDataClick }) {
  return (
    <>
      {chartData.caption && <Typography variant="h5">{chartData.caption}</Typography>}
      <ComparisonChart
        data={chartData.data}
        chartType={chartData.chartType}
        topLimit={chartData.topLimit}
        colors={chartData.colors}
        onDataClick={onDataClick}
      />
      <Typography variant="caption">{chartData.caption}</Typography>
    </>
  );
}
export function ChartCell({ chartData, gridSize, topLimit }) {
  const content = <ChartCellContent chartData={chartData} topLimit={topLimit} />;
  return (
    <Grid
      item
      xs={6}
      md={gridSize}
      sx={{
        border: '1px solid #ccc',
        textAlign: 'center',
        padding: 2,
      }}
    >
      {content}
    </Grid>
  );
}
