import ReactGA from 'react-ga4';

export const initGA = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }
};

export const logPageView = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const logEvent = (category = '', action = '', label = '') => {
  if (process.env.REACT_APP_ENV === 'production' && category && action) {
    ReactGA.event({ category, action, label });
  }
};
