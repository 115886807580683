import { useState, useEffect, useRef, useCallback } from 'react';

import api, { Persona, PersonaWebsite } from 'api';
import { debounce } from 'Helpers/debounce';

interface GalleryState {
  websites: PersonaWebsite[];
  hasMore: boolean;
  isLoading: boolean;
}

const DEBOUNCE_TIME = 600; // ms

const debouncer = debounce(DEBOUNCE_TIME);

const usePersonaGallery = (
  persona: Persona | null,
  domain: string | null,
  language: string | null,
) => {
  const [{ hasMore, isLoading, websites }, setState] = useState<GalleryState>({
    websites: [],
    hasMore: true,
    isLoading: false,
  });

  const fetchWebsites = useCallback(async () => {
    if (!persona || !hasMore) return;
    const storedWebsites = loadStoredWebsites(persona, domain, language);
    if (storedWebsites) {
      setState({
        websites: storedWebsites.websites,
        hasMore: storedWebsites.hasMore,
        isLoading: false,
      });
      return;
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const resp = await api.persona.searchPersona(persona.meta.id, domain, language);
      const newVideos = resp.body?.websites || [];

      setState((prevState) => {
        const websiteSet = new Set(prevState.websites.map((w) => w.video_id));
        const uniqueNewWebsites = newVideos.filter((w) => !websiteSet.has(w.video_id));
        const websiteUpdate = [...prevState.websites, ...uniqueNewWebsites];
        const hasMoreUpdate = resp.body?.pagination?.next !== null;
        const newState = {
          ...prevState,
          websites: websiteUpdate,
          hasMore: hasMoreUpdate,
          isLoading: false,
        };
        saveStoredWebsites(persona, domain, language, websiteUpdate, hasMoreUpdate);
        return newState;
      });
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [persona, hasMore, domain, language]);

  const resetGallery = useCallback(() => {
    setState({ websites: [], hasMore: true, isLoading: false });
  }, []);

  useEffect(() => {
    if (persona && !isLoading && websites.length === 0) {
      fetchWebsites();
    }
  }, [persona, fetchWebsites]);

  const debouncedRefreshWebsites = useRef(
    debouncer(() => {
      resetGallery();
      fetchWebsites();
    }),
  ).current;

  useEffect(() => {
    if (persona && !isLoading) {
      debouncedRefreshWebsites();
    }
  }, [domain, language]);

  return {
    websites,
    resetGallery,
    fetchWebsites,
    state: {
      hasMore,
      isLoading,
    },
  };
};

export default usePersonaGallery;

interface SavedWebsites {
  websites: PersonaWebsite[];
  hasMore: boolean;
  timestamp: number;
}

const saveStoredWebsites = (
  persona: Persona | null,
  domain: string | null,
  language: string | null,
  websites: PersonaWebsite[],
  hasMore: boolean,
) => {
  if (!persona) return null;
  const key = getStorageKey(persona, domain, language);
  const savedWebsites = { websites, hasMore, timestamp: Date.now() };
  const storedString = JSON.stringify(savedWebsites);
  localStorage.setItem(key, storedString);
};

const loadStoredWebsites = (
  persona: Persona | null,
  domain: string,
  language: string,
): SavedWebsites | null => {
  if (!persona) return null;
  const key = getStorageKey(persona, domain, language);
  const storedString = localStorage.getItem(key);
  if (storedString) {
    console.log('Found key', key);
    try {
      const savedWebsites: SavedWebsites = JSON.parse(storedString);
      if (savedWebsites.timestamp && Date.now() - savedWebsites.timestamp < 3600000) {
        return savedWebsites;
      }
    } catch {
      console.error('Failed to load saved websites for key', key);
    }
  } else {
    console.log('Key not found', key);
  }
  return null;
};

const getStorageKey = (
  persona: Persona,
  domain: string | null,
  language: string | null,
): string => {
  const topics = persona.topics
    .filter((t) => t.is_active)
    .map((t) => t.id)
    .join('-');
  const key = `${persona.meta.id}-${domain || ''}-${language || ''}-${topics}`;
  return key;
};
