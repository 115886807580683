import React from 'react';

import Page from '../Components/Page';
import VideoInfo from '../Components/Scorecard/VideoInfo';

const ContentDetailPage = () => {
  return (
    <Page showSearch greyBackground customBackground customBackgroundColor="#ECEFF1">
      <VideoInfo />
    </Page>
  );
};

export default ContentDetailPage;
