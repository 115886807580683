import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Page from 'Components/Page';

export default function ContactPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://webforms.pipedrive.com/f/loader';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Page greyBackground>
      <CentredLayout>
        <Column>
          <Title>Contact Us</Title>
          <p>Interested in learning more? Fill out the contact form and someone from our team will be in touch shortly.</p>
        </Column>
        <Column>
          <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/bZ29nvXO7EYhx7DTrVU2b0hAYgI8alahFXBfnEMwsz0oTNDQD9bqtanSYRjKmPAVlF" />
        </Column>
      </CentredLayout>
    </Page>
  );
}

const CentredLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  justify-content: space-between;
  padding: 3rem 0 4rem;
  text-align: left;
  max-width: 960px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Column = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  margin: 0 0 1rem;
`;
