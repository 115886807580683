import React, {useState} from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '@emotion/styled';

interface Props {
  loadingPercentage: number;
  loadingLabel?: string;
  margin?: string;
}

const LoadingBar: React.FC<Props> = ({ loadingPercentage, loadingLabel, margin }) => {
  const [hideLoadingBar, setHideLoadingBar] = useState(false);
  function handleHideLoadingBar () {
    setHideLoadingBar(true);
  }

  return (
  <ProgressItems margin={margin} sx={{ visibility: hideLoadingBar ? 'hidden': 'visible' }}>
    {loadingPercentage > 0 && loadingPercentage < 99.9 && (
      <Box position="relative" width="100%">
        <LinearProgress
          variant="determinate"
          value={loadingPercentage}
          sx={{
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: '#4CAF50',
            },
            height: 24,
            backgroundColor: 'white',
            borderRadius: 5,
            position: 'relative',
          }}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          paddingLeft="9px"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Typography fontSize="12px" fontWeight="600" sx={{ color: 'white', marginRight: 0.5 }}>
            {loadingLabel}
          </Typography>
        </Box>
      </Box>
    )}
    {loadingPercentage === 99.9 && (
      <Box position="relative" width="100%" sx={{ color: 'lightgreen' }}>
        <LinearProgress
          variant="determinate"
          value={100}
          sx={{
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: '#4CAF50',
            },
            height: 24,
            borderRadius: 5,
            position: 'relative',
          }}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          paddingLeft="9px"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Typography fontSize="12px" fontWeight="600" sx={{ color: 'white', marginRight: 0.5 }}>
          {loadingPercentage === 99.9 && 'READY'}
          </Typography>
        </Box>
        <Box
          position="absolute"
          top="0"
          right="0"
          paddingRight="6px"
          height="100%"
          display="flex"
          alignItems="center"
          onClick={handleHideLoadingBar}
        >
          <CheckCircleIcon sx={{ color: 'white', fontSize: 16 }} />
        </Box>
      </Box>
    )}
  </ProgressItems>
);};

export default LoadingBar;

const ProgressItems = styled(Box)<{ margin?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => props.margin || 'auto'};
  padding-top: 10px;
  box-sizing: border-box;
  width: 100%;
`;
