const Env = {
  Dev: 'dev',
  Test: 'test',
  Staging: 'staging',
  Prod: 'production',
  IrisTest: 'iris-test',
  IrisProd: 'iris-production',
  CurrentEnv: process.env.REACT_APP_ENV,
  PersonaEnvs: ['dev', 'test', 'staging'],
  SearchEnvs: ['dev', 'test', 'staging'],
};

export default Env;
