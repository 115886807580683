import React from 'react';
import { useSearchParams } from 'react-router-dom';

// useState except it gets/sets the page querystring
export const useQueryState = <T>(
  paramName: string,
  initialValue: T,
  loadValue: (s: string | null) => T | null,
  serializeValue: (v: T) => string,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // Querystring params via react router.
  const [searchParams, setSearchParams] = useSearchParams();

  // Prefer querystring value over default initial value.
  const getInitialValue = React.useCallback(() => {
    const valueStr = searchParams.get(paramName);
    const loadedValue = loadValue(valueStr);
    if (loadedValue !== null) {
      return loadedValue;
    } else {
      return initialValue;
    }
  }, []);
  const [value, _setValue] = React.useState<T>(getInitialValue());

  // Update the querystring when the state is updated.
  const setValue = (newValue: T): void => {
    try {
      const serializedValue = serializeValue(newValue);
      searchParams.set(paramName, serializedValue);
      setSearchParams(searchParams);
    } catch (error) {
      console.error(
        `Failed to serialize '${paramName}' querystring parameter on state update.` +
          `\n\nValue \`${newValue}` +
          `\`\n\n${error}`,
      );
    }
    _setValue(newValue);
  };

  return [value, setValue as React.Dispatch<React.SetStateAction<T>>];
};

export type QueryStringData = Record<string, string | null>;

export const useObjectQueryState = <T>(
  paramNames: string[],
  loadValue: (qs: QueryStringData) => T,
  serializeValue: (v: T) => QueryStringData,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // Querystring params via react router.
  const [searchParams, setSearchParams] = useSearchParams();

  // Prefer querystring value over default initial value.
  const getInitialValue = React.useCallback(() => {
    const qsData: QueryStringData = {};
    for (let paramName of paramNames) {
      qsData[paramName] = searchParams.get(paramName);
    }
    return loadValue(qsData);
  }, []);

  const [value, _setValue] = React.useState<T>(getInitialValue());

  // Update the querystring when the state is updated.
  const setValue = (newValue: T): void => {
    try {
      const newQsData = serializeValue(newValue);
      for (let [paramName, serializedValue] of Object.entries(newQsData)) {
        if (serializedValue === '') {
          searchParams.delete(paramName);
        } else {
          searchParams.set(paramName, serializedValue);
        }
      }
      setSearchParams(searchParams);
    } catch (error) {
      console.error(
        `Failed to serialize querystring parameter on state update.` +
          `\n\nValue \`${newValue}` +
          `\`\n\n${error}`,
      );
    }
    _setValue(newValue);
  };

  return [value, setValue as React.Dispatch<React.SetStateAction<T>>];
};
