export function getChartOptions(config) {
  const { chartType, categories, values, customColors, isPercentage, onDataClick, options = {} } = config;
  const {
    isDarkMode = false,
    size = 'medium',
    sortByValue = false,
    topLimit,
    customDecimals = 0,
    customTooltip,
    chartReducedSpace = 0,
    caption,
    showLegend = true, // Default to true to show the legend
  } = options;

  const labelColor = isDarkMode ? '#ffffff' : '#ffffff';
  const backgroundColor = isDarkMode ? 'transparent' : 'transparent';
  const COLORS = customColors || [
    '#3691ec',
    '#54e2c8',
    '#71cf3b',
    '#fbd344',
    '#ed664b',
    '#eb4894',
    '#2ee0ca',
    '#fa4b42',
    '#feb56a',
    '#337CCF',
  ];
  console.log(chartType, isPercentage);
  const chartHeight = (() => {
    switch (size) {
      case 'small':
        return 85;
      case 'medium':
        return 210;
      case 'large':
        return 230;
      default:
        return 250;
    }
  })();

  const fontSize = size === 'small' ? '9px' : '11px';

  // if topLimit is defined, slice from index 0 to topLimit
  // if topLimit is not defined, include all items (categories.length)
  const sortedData = sortByValue
    ? categories.sort((a, b) => b.value - a.value).slice(0, topLimit || categories.length)
    : categories;

  const sortedCategories = sortedData.map((item) => item.label);
  const sortedValues = values.map((value, index) => ({
    name: sortedCategories[index],
    y: value,
    color:
      chartType === 'bar' || chartType === 'column' ? COLORS[index % COLORS.length] : undefined, // Assign a color from the COLORS array for bar and column charts
  }));

  const baseOptions = {
    chart: {
      type: chartType,
      backgroundColor,
      height: chartHeight - chartReducedSpace,
      animation: false,
      ...(chartType === 'pie' && {
        events: {
          load: function () {
            const chart = this;
            chart.legend.update({
              x: chart.plotLeft - 10,
              y: chart.plotTop - 15,
            });
          },
        },
      }),
    },
    title: {
      text: caption || undefined, // caption is optional
    },
    credits: {
      enabled: false,
    },
    colors: COLORS,
    legend: {
      enabled: showLegend && chartType !== 'bar' && chartType !== 'column', // Disable legend for bar and column charts
      layout: 'vertical', // Arrange legend items vertically
      floating: chartType === 'pie',
      align: chartType === 'pie' ? 'center' : 'right', // Center align for pie charts
      verticalAlign: chartType === 'pie' ? 'middle' : 'top', // Middle vertical align for pie charts
      itemStyle: {
        color: labelColor,
        fontSize: '14px', // Increase font size for better visibility
        fontWeight: 'bold', // Make the font bold
      },
    },
    plotOptions: {
      series: {
        animation: false,
        events: {
          legendItemClick: (e) => {
            e.preventDefault();
            return false;
          },
        },
        point: {
          events: {
            click: function () {
              if (!onDataClick) return;
              if (chartType === 'bar' || chartType === 'column') {
                onDataClick(this.category, this.y);
              }
              if (chartType === 'pie') {
                onDataClick(this.name, this.y);
              }
            },
          },
        },
      },
      ...(chartType === 'pie' && {
        pie: {
          dataLabels: {
            enabled: false, // Disable data labels
          },
          borderWidth: 0.5,
          borderRadius: 0,
          size: '120%',
          innerSize: '75%', // Make the pie "skinnier"
          showInLegend: showLegend, // Show legend
          cursor: 'pointer',
        },
      }),
      ...((chartType === 'bar' || chartType === 'column') && {
        bar: {
          pointWidth: 20,
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderWidth: 0.5,

          dataLabels: {
            enabled: false,
            formatter: function () {
              return this.y % 1 === 0 ? this.y.toFixed(0) : this.y.toFixed(customDecimals);
            },
            style: {
              color: labelColor,
              fontSize,
            },
          },
          cursor: 'pointer',
        },
        column: {
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 0.5,
          dataLabels: {
            enabled: false,
            formatter: function () {
              return this.y % 1 === 0 ? this.y.toFixed(0) : this.y.toFixed(customDecimals);
            },
            style: {
              color: labelColor,
              fontSize,
            },
          },
          cursor: 'pointer',
        },
      }),
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        const pct = isPercentage ? '%' : '';
        const value = this.y % 1 === 0 ? this.y.toFixed(0) : this.y.toFixed(customDecimals);
        return `<b>${this.point.name}:</b> ${value}${pct}`;
      },
    },
    series: [
      {
        type: chartType,
        name: customTooltip || (isPercentage ? '% of Total' : 'Total Count'),
        data: sortedValues,
      },
    ],
  };

  if (chartType === 'bar' || chartType === 'column') {
    baseOptions.xAxis = {
      categories: sortedCategories,
      labels: {
        style: {
          color: labelColor,
          fontSize,
        },
      },
    };
    baseOptions.yAxis = {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: labelColor,
          fontSize,
        },
      },
    };
  }

  return baseOptions;
}
