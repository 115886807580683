import React, { useState } from 'react';
import styled from '@emotion/styled';

interface TextAreaProps {
  message: string;
  setMessage: (message: string) => void;
  disabled?: boolean;
  placeholder: string;
  maxWidth?: number;
}

const MIN_ROWS = 2;
const MAX_ROWS = 12;
const ROW_HEIGHT = 20; // px

const TextArea: React.FC<TextAreaProps> = ({ message, setMessage, disabled, placeholder }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState<number>(MIN_ROWS);
  React.useEffect(() => {
    if (textareaRef.current) {
      const textWidth = textareaRef.current.offsetWidth - 16; // padding
      const fontSize = parseFloat(window.getComputedStyle(textareaRef.current).fontSize);
      const numLines = getLineCount(textWidth, fontSize, message);
      const newHeight = numLines * ROW_HEIGHT;
      textareaRef.current.style.height = newHeight.toString();
      setRows(numLines);
    }
  }, [message]);

  return (
    <TextContainer>
      <TextAreaEl
        disabled={disabled}
        ref={textareaRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={placeholder}
        rows={rows}
      />
    </TextContainer>
  );
};

const getLineCount = (textWidth: number, fontSize: number, text: string): number => {
  let numLines = 0;
  const charsPerLine = Math.floor((1.65 * textWidth) / fontSize);
  const newLines = text.split('\n');
  for (let newLine of newLines) {
    numLines += 1;
    numLines += Math.floor(newLine.length / charsPerLine);
  }
  const numLinesClipped = Math.max(MIN_ROWS, Math.min(MAX_ROWS, numLines));
  return numLinesClipped;
};

const TextContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

const TextAreaEl = styled.textarea`
  flex-grow: 1;
  border: none;
  background: transparent;
  font-family: inherit; // Use the standard font family
  resize: none;
  min-height: calc(${MIN_ROWS} * ${ROW_HEIGHT}px);
  max-height: calc(${MAX_ROWS} * ${ROW_HEIGHT}px);
  padding: 8px;
  &:focus {
    outline: none;
  }
  // Hide scrollbar
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, and Opera
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export default TextArea;
