type Timer = NodeJS.Timeout | undefined;

export const debounce = (delay: number) => {
  let timer: Timer = undefined;
  return (func: any) => {
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
};
