import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import api, { PersonaPagination, PersonaWebsite } from 'api';
import Page from 'Components/Page';
import CardFlip from 'Components/Gallery/CardFlip';
import Input from 'Components/Persona/Input';
import LanguageSelect from 'Components/Persona/LanguageSelect';
import { useObjectQueryState, QueryStringData } from 'Helpers/useQueryState';

const PAGE_SIZE = 50;

interface GalleryState {
  websites: PersonaWebsite[];
  pagination: PersonaPagination | null;
  hasMore: boolean;
  isLoading: boolean;
}

interface SearchState {
  query: string;
  domain: string;
  language: string;
}

const INIT_GALLERY = {
  websites: [],
  pagination: null,
  hasMore: true,
  isLoading: false,
};

const INIT_SEARCH = { query: '', domain: '', language: '' };

const SearchPage = () => {
  const isLoadingRef = useRef<boolean>(false);
  const [search, setSearch] = useObjectQueryState<SearchState>(
    ['query', 'domain', 'language'],
    loadSearchState,
    serializeSearchState,
  );

  const [gallery, setGallery] = useState<GalleryState>(INIT_GALLERY);

  const fetchWebsites = useCallback(async (search: SearchState, gallery: GalleryState) => {
    const { pagination } = gallery;
    if (!search.query) return;
    setGallery((prevState) => ({ ...prevState, isLoading: true }));
    const page = pagination?.next || 1;
    try {
      const resp = await api.persona.searchQuery(
        search.query,
        page,
        PAGE_SIZE,
        search.domain,
        search.language,
      );
      const newVideos = resp.body?.websites || [];

      setGallery((prevState) => {
        const websiteSet = new Set(prevState.websites.map((w) => w.video_id));
        const uniqueNewWebsites = newVideos.filter((w) => !websiteSet.has(w.video_id));
        const websiteUpdate = [...prevState.websites, ...uniqueNewWebsites];
        const newState = {
          ...prevState,
          websites: websiteUpdate,
          pagination: resp.body?.pagination,
          hasMore: resp.body?.pagination?.next !== null,
        };
        return newState;
      });
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setGallery((prevState) => ({ ...prevState, isLoading: false }));
      isLoadingRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (search.query && !isLoadingRef.current) {
      isLoadingRef.current = true;
      fetchWebsites(search, gallery);
    }
  }, []);

  const onSearch = () => {
    setGallery(INIT_GALLERY);
    fetchWebsites(search, INIT_GALLERY);
  };

  const onClear = () => {
    setGallery(INIT_GALLERY);
    setSearch(INIT_SEARCH);
  };

  return (
    <Page greyBackground title="Search">
      <CentredLayout>
        <SearchBox>
          <Title>Search</Title>
          <SearchInput
            placeholder="Search for websites..."
            value={search.query}
            disabled={gallery.isLoading}
            rows={5}
            onChange={(e) => setSearch({ ...search, query: e.target.value })}
          />
          <Input
            type="text"
            autoComplete="off"
            disabled={gallery.isLoading}
            value={search.domain}
            onChange={(e) => setSearch({ ...search, domain: e.target.value.toLowerCase() })}
            placeholder="Domain (site.com)"
          />
          <LanguageSelect
            value={search.language}
            disabled={gallery.isLoading}
            onChange={(language) => setSearch({ ...search, language })}
            placeholder="Language"
          />
          <Buttons>
            <ClearButton disabled={gallery.isLoading} onClick={onClear}>
              Clear
            </ClearButton>
            <SendButton
              variant="contained"
              size="small"
              onClick={onSearch}
              disabled={gallery.isLoading || !search.query}
            >
              Search
            </SendButton>
          </Buttons>
        </SearchBox>

        {search.query && (
          <GalleryContainer>
            <InfiniteScroll
              style={{ overflowX: 'hidden', alignItems: 'center', justifyContent: 'center' }}
              dataLength={gallery.websites.length}
              next={() => fetchWebsites(search, gallery)}
              hasMore={gallery.hasMore}
              loader={<></>}
              scrollThreshold={0.2}
              endMessage={!gallery.isLoading && <EndMessage>No more content</EndMessage>}
            >
              <ResponsiveMasonryStyled columnsCountBreakPoints={BREAKPOINTS}>
                <Masonry gutter="5px">
                  {gallery.websites.map((website) => (
                    <Link
                      to={`/content/${encodeURIComponent(website.url)}`}
                      state={{
                        content: null,
                        creatorName: website.creator_name,
                        contentTitle: website.title,
                        from: '/search',
                      }}
                      key={website.video_id}
                    >
                      <CardFlip
                        imageSrc={website.thumbnail_url}
                        mainContent={website.title}
                        title={website.creator_name}
                        backContent={website.title}
                      />
                    </Link>
                  ))}
                </Masonry>
              </ResponsiveMasonryStyled>
            </InfiniteScroll>
          </GalleryContainer>
        )}
      </CentredLayout>
    </Page>
  );
};

export default SearchPage;

const loadSearchState = (qs: QueryStringData): SearchState => {
  const loadedState = {
    domain: qs['domain'] ?? '',
    language: qs['language'] ?? '',
    query: qs['query'] ?? '',
  };
  const idQuery = qs['id'];
  if (idQuery !== null) {
    const parsed = parseInt(idQuery, 10);
    loadedState['id'] = isNaN(parsed) ? null : parsed;
  }
  return loadedState;
};

const serializeSearchState = (state: SearchState): QueryStringData => ({
  domain: state.domain ?? '',
  language: state.language ?? '',
  query: state.query ?? '',
});

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0 0 0 0;
`;

const CentredLayout = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0 0 0;

  justify-content: start;
  @media (max-width: 800px) {
    padding: 1em 0 0 0;
  }
`;

const SearchBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  max-width: 800px;
`;

const SearchInput = styled.textarea`
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    font-size: 16px;
  }

  font-family: inherit; // Use the standard font family
  resize: none;
  &:focus {
    outline: none;
  }
  // Hide scrollbar
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, and Opera
  }
`;

const GalleryContainer = styled.div`
  width: 100%;
`;

const ResponsiveMasonryStyled = styled(ResponsiveMasonry)`
  margin: 0 auto;
  @media (min-width: 1500px) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const EndMessage = styled.h4`
  text-align: center;
  font-size: 1em;
  color: #000;
  opacity: 0.5;
  font-weight: normal;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;
  justify-content: flex-end;
`;

const ClearButton = styled(Button)`
  background-color: transparent;
  text-transform: capitalize;
  padding: 0;
  font-size: 0.75em;
  box-shadow: none;
  color: #263238;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const SendButton = styled(Button)`
  min-width: 80px;
  background-color: #263238;
  text-transform: capitalize;
  &:hover {
    background-color: #263238;
  }
`;

const BREAKPOINTS = {
  25: 1,
  200: 2,
  300: 3,
  328: 4,
  490: 4,
  675: 5,
  850: 6,
  1000: 6,
  1400: 6,
  1850: 6,
  2000: 6,
  2675: 9,
  3000: 12,
  3250: 13,
};
