import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './SentimentIndicator.css';
import PositiveSentimentFace from '../../assets/positive_sentiment_face.svg';
import NegativeSentimentFace from '../../assets/negative_sentiment_face.svg';
import NeutralSentimentFace from '../../assets/neutral_sentiment_face.svg';
import LoadingSentimentFace from '../../assets/loading_sentiment_face.svg';

const SentimentIndicator = ({ sentiment }) => {
  const [sentimentLabel, setSentimentLabel] = useState(null);
  const [sentimentLabelColor, setSentimentLabelColor] = useState('#A9A9A9');
  const [sentimentFace, setSentimentFace] = useState(null);

  const getSentimentDetails = (sentiment) => {
    switch (sentiment) {
      case 'Positive':
        setSentimentLabel(sentiment);
        setSentimentLabelColor('#4CAF50');
        setSentimentFace(PositiveSentimentFace);
        break;
      case 'Negative':
        setSentimentLabel(sentiment);
        setSentimentLabelColor('#FF4C4C');
        setSentimentFace(NegativeSentimentFace);
        break;
      case 'Neutral':
        setSentimentLabel(sentiment);
        setSentimentLabelColor('#FF9800');
        setSentimentFace(NeutralSentimentFace);
        break;
      default:
        setSentimentLabel(null);
        setSentimentLabelColor('#ECEFF1');
        setSentimentFace(LoadingSentimentFace);
    }
  };

  useEffect(() => {
    getSentimentDetails(sentiment);
  }, [sentiment]);

  return (
    <div className="tooltip" height="100%">
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '40%',
            maxWidth: '120px',
            marginRight: '5%',
            '@media (min-width: 650px)': {
              marginLeft: '5%',
            },
          }}
        >
          <img
            src={sentimentFace}
            alt="sentiment face"
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '100px',
              margin: '2px',
            }}
          />
        </Box>
        <Box sx={{ width: '55%' }}>
          {sentimentLabel ? (
            <Typography
              sx={{
                color: sentimentLabelColor,
                textTransform: 'uppercase',
                fontSize: '21px',
                fontWeight: '600',
                '@media (max-width: 650px)': {
                  fontSize: '16px',
                },
                '@media (max-width: 400px)': {
                  fontSize: '12px',
                },
              }}
            >
              {sentimentLabel}
            </Typography>
          ) : (
            <Box width="90%" maxWidth="88px" backgroundColor="#ECEFF1" height="15px"></Box>
          )}
          <Typography
            fontSize={'14px'}
            fontWeight={400}
            color={'#90A4AE'}
            sx={{
              '@media (max-width: 400px)': {
                fontSize: '10px',
              },
            }}
          >
            Sentiment
          </Typography>
        </Box>
      </Box>
      <div className="tooltiptext">The sentiment of the content</div>
    </div>
  );
};

export default SentimentIndicator;
