import { mbsApi } from './mbs';
import { personaApi } from './persona';

export * from './types';
export const api = {
  mbs: mbsApi,
  persona: personaApi,
};

export default api;
