import React, { createContext } from 'react';

interface UrlContextType {
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const UrlContext = createContext<UrlContextType>({
  url: '',
  setUrl: () => {},
});
