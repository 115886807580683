import React, { useEffect, useCallback, useContext, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

import api, { Persona } from 'api';
import { FiltersContext, UrlContext } from 'context';
import AdditionalContext from './AdditionalContext';
import ContentEmbed from './ContentEmbed';
import LoadingBar from './LoadingBar';
import RiskGauge from './RiskGauge';
import SentimentIndicator from './SentimentIndicator';
import MetadataTagDisplay from './MetadataTagDisplay';
import MetadataSummary from './MetadataSummary';

import 'App.css';
import './VideoInfo.css';

const VideoInfo = () => {
  const { setUrl } = useContext(UrlContext);
  const { videoUrl } = useParams();
  const location = useLocation();
  const fromPage = location.state?.from ?? '/';

  const [personas, setPersonas] = useState<Persona[] | null>(null);
  const [riskLabel, setRiskLabel] = useState(location.state?.content?.gpt_garm_level ?? null);
  const [source, setContentSource] = useState(location.state?.content?.source || null);
  const [riskDescription, setRiskDescription] = useState('');
  const [creatorName, setCreatorName] = useState(location.state?.creatorName || null);
  const [videoTitle, setVideoTitle] = useState(location.state?.contentTitle || null);
  const [highlights, setHighlights] = useState(location.state?.content?.highlights || null);
  const [textCollectStatus, setTextCollectStatus] = useState(
    location.state?.content?.text_collect_status ?? null,
  );
  const [imageCollectStatus, setImageCollectStatus] = useState(
    location.state?.content?.image_collect_status ?? null,
  );
  const [postCollectStatus, setPostCollectStatus] = useState(
    location.state?.content?.post_collect_status ?? null,
  );
  const [processStatus, setProcessStatus] = useState(location.state?.content?.gpt_complete ?? null);
  const [riskLabelMap, setRiskLabelMap] = useState(null);
  const [sentimentLabelMap, setSentimentLabelMap] = useState(null);
  const [gptRiskDescription, setGPTRiskDescription] = useState(
    location.state?.content?.gpt_risk_desc || '',
  );
  const [gptSummary, setGPTSummary] = useState(location.state?.content?.gpt_summary || null);
  const [gptSentiment, setGPTSentiment] = useState(location.state?.content?.gpt_sentiment ?? null);
  // const [riskCategoriesMap, setRiskCategoriesMap] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [emotionData, setEmotionData] = useState(null);
  const [emotionDataReady, setEmotionDataReady] = useState(false);
  const [topicData, setTopicData] = useState(null);
  const [topicDataReady, setTopicDataReady] = useState(false);
  const [metadataReady, setMetadataReady] = useState(false);
  const [videoId, setVideoId] = useState(location.state?.content?.video_id || null);
  const [loadingPercentage, setLoadingPercentage] = useState(
    location.state?.content?.loading_percentage || 0,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const timeoutRefs = useRef([]);
  // TESTING
  const [isTestingLoading, setIsTestingLoading] = useState(false);

  const {
    clearFilters,
    setGarmRiskLevelFilters,
    setGarmRiskLevelFiltersDisplay,
    setSentimentFilters,
    setSentimentFiltersDisplay,
  } = useContext(FiltersContext);

  const navigate = useNavigate();
  const NEVER_METADATA_SOURCE_TYPES = ['cloud_file_storage'];
  const WAITING_STATUSES = [2, 0, undefined];
  const NO_CONTENT = -1;
  const BACKLOGGED_STATUS = 3;
  const maximumProcessingPercentage = 95.0;

  const fetchLabels = async (labelType) => {
    const labelsResponse = await api.mbs.getLabels(labelType);
    if (labelsResponse.categories !== undefined) {
      const catsDict = labelsResponse.categories.reduce((accumulator, current) => {
        accumulator[current.id] = current;
        return accumulator;
      }, {});
      return catsDict;
    }
    if (labelsResponse.labels !== undefined) {
      const labelsDict = labelsResponse.labels.reduce((accumulator, current) => {
        accumulator[current.id] = current;
        return accumulator;
      }, {});
      return labelsDict;
    }
  };
  const fetchInitialData = async () => {
    const newRiskLabelMap = await fetchLabels('risk-labels');
    console.log('Risk label map:', newRiskLabelMap);
    setRiskLabelMap(newRiskLabelMap);

    const newSentimentLabelMap = await fetchLabels('sentiment-labels');
    setSentimentLabelMap(newSentimentLabelMap);
  };
  useEffect(() => {
    fetchInitialData();
  }, []);

  const determineRiskDescription = (transcriptStatus, videoStatus, postStatus) => {
    const labels = {
      article: { imageLabel: 'Image', textLabel: 'Article', postLabel: 'Post' },
      xsocial: { imageLabel: 'Video', textLabel: 'Transcript', postLabel: 'Post' },
      default: { imageLabel: 'Video', textLabel: 'Transcript', postLabel: 'Post' },
    };

    const { imageLabel, textLabel, postLabel } = labels[source] || labels.default;

    const statuses = [
      { status: transcriptStatus, label: textLabel },
      { status: videoStatus, label: imageLabel },
      { status: postStatus, label: postLabel },
    ];

    const evaluated = statuses.filter((item) => item.status === 1).map((item) => item.label);

    if (evaluated.length === 0) {
      return 'Nothing to Evaluate';
    } else if (evaluated.length === 3) {
      return `${evaluated[0]}, ${evaluated[1]} & ${evaluated[2]} Evaluated`;
    } else if (evaluated.length === 2) {
      return `${evaluated[0]} & ${evaluated[1]} Evaluated`;
    } else {
      return `${evaluated[0]} Evaluated`;
    }
  };

  useEffect(() => {
    if (videoUrl) {
      const decodedUrl = decodeURIComponent(videoUrl);
      setUrl(decodedUrl);

      // If video data is not available in location state, fetch it
      if (!location.state?.content) {
        console.log('No video data in location state, fetching from API...');
        setShowProgressBar(true);
        fetchVideoData(decodedUrl);
      } else {
        console.log('Passed risk label:', location.state?.content?.gpt_garm_level);

        setTopicDataReady(true);
        setEmotionDataReady(true);
        setMetadataReady(true);
        setRiskDescription(
          determineRiskDescription(textCollectStatus, imageCollectStatus, postCollectStatus),
        );
        // console.log(riskLabel);
        // console.log(riskLabelMap);
        // console.log(sentimentLabelMap);
        // console.log("Sentiment:", gptSentiment);
        // // console.log(sentimentLabelMap[gptSentiment]?.label);
        // // console.log(riskLabelMap[parseInt(riskLabel)]?.label);
        // console.log(riskDescription);
        // console.log(isLoading);
      }
    }
  }, [videoUrl, location.state?.content]);

  useEffect(() => {
    const fetchTopics = async () => {
      if (topicDataReady && videoId) {
        try {
          const topicResponse = await api.mbs.getVideoTopic(videoId);
          setTopicData(topicResponse.body.topics);
        } catch (error) {
          console.error('Failed to fetch topics:', error);
        }
      }
    };

    fetchTopics();
  }, [videoId, topicDataReady]);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (metadataReady && videoUrl) {
        try {
          const metadataResponse = await api.mbs.getMetadata(decodeURIComponent(videoUrl));
          if (metadataResponse.status === 200) {
            if (metadataResponse.body.source) {
              setContentSource(metadataResponse.body.source);
            }
            setMetadata(metadataResponse.body);
          } else {
            console.error('Failed to fetch metadata:', metadataResponse);
          }
        } catch (error) {
          console.error('Failed to fetch metadata:', error);
        }
      }
    };
    if (source && !NEVER_METADATA_SOURCE_TYPES.includes(source)) {
      fetchMetadata();
    }
  }, [videoUrl, metadataReady]);

  useEffect(() => {
    const fetchEmotion = async () => {
      if (emotionDataReady && videoId) {
        try {
          const emotionResponse = await api.mbs.getVideoEmotion(videoId);
          setEmotionData(emotionResponse.body.emotion);
        } catch (error) {
          console.error('Failed to fetch topics:', error);
        }
      }
    };

    fetchEmotion();
  }, [videoId, emotionDataReady]);

  function checkIfOneDayAgo(processingEnd) {
    if (!processingEnd) {
      return false;
    }
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const differenceInSeconds = nowInSeconds - processingEnd;
    const oneDayInSeconds = 86400;

    return differenceInSeconds > oneDayInSeconds;
  }

  const fetchPersonaData = useCallback(async (url) => {
    let personaIds = [];
    try {
      const webpageDetailResp = await api.persona.webpageDetail(url);
      const personaIds = webpageDetailResp.body?.persona_ids || [];
      const videoPersonas = [];
      for (let personaId of personaIds) {
        const personaResponse = await api.persona.personaDetail(personaId);
        const persona = personaResponse.body;
        if (persona) {
          videoPersonas.push(persona);
        }
      }
      if (videoPersonas.length > 0) {
        setPersonas(videoPersonas);
      }
    } catch (error) {
      console.error('Failed to load persona webpage detail', error);
    }
  }, []);

  useEffect(() => {
    if (riskLabel !== NO_CONTENT) {
      fetchPersonaData(videoUrl);
    }
  }, [videoUrl, riskLabel]);

  const fetchVideoData = async (url) => {
    setTextCollectStatus(null);
    setImageCollectStatus(null);
    setPostCollectStatus(null);
    setHighlights(null);
    setProcessStatus(null);
    setRiskLabel(null);
    setRiskDescription('');
    setLoadingPercentage(0);

    setGPTRiskDescription(null);
    setGPTSummary(null);
    setMetadata(null);

    setShowAlert(false);
    setAlertMessage('');
    setVideoId(null);
    setContentSource(null);
    setTopicData(null);
    setTopicDataReady(false);
    setEmotionDataReady(false);
    setMetadataReady(false);
    // setProcessData(null);
    setGPTSentiment(null);
    setEmotionData(null);
    setCreatorName(null);
    setVideoTitle(null);

    if (!url) {
      console.log('No valid url!');
      return;
    }

    setIsLoading(true);
    setLoadingLabel('Submitting url for processing...');
    const addResponse = await api.mbs.addVideo(url);

    if (addResponse.body.gpt_complete === NO_CONTENT || addResponse.body.gpt_complete === BACKLOGGED_STATUS) {
      setRiskLabel(addResponse.body.gpt_complete);
      setIsLoading(false);
      setLoadingLabel(null);
      setLoadingPercentage(0);
      setAlertMessage(
        `Unable to access ${addResponse.body.content_type} for processing. Redirecting to gallery...`,
      );
      setShowAlert(true);
      setShowProgressBar(false);
      const id = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage('');
        navigate('/');
      }, 10000);
      setTimeoutId(id);
    }
    if (addResponse.body.video_id) {
      setVideoId(addResponse.body.video_id);
    }
    if (addResponse.body.source) {
      setContentSource(addResponse.body.source);
    }
    if (isTestingLoading) {
      // Simulate loading by skipping the rest of the function
      setIsLoading(true);
      setLoadingLabel('Testing loading state...');
      setTimeout(() => fetchVideoData(url), 60000); // Re-trigger the function to simulate continuous loading
      return;
    }
    // setGPTTranscriptStatus(addResponse.body.t_gpt_complete);
    // setGPTVideoStatus(addResponse.body.v_gpt_complete);
    setProcessStatus(addResponse.body.gpt_complete);
    if (addResponse.body.source === 'youtube') {
      setCreatorName(addResponse.body.channel_name);
      setVideoTitle(addResponse.body.video_title);
    } else if (addResponse.body.source === 'article') {
      setCreatorName(addResponse.body.channel_id);
      setVideoTitle(addResponse.body.article_title);
    } else if (addResponse.body.source === 'tiktok') {
      setCreatorName(addResponse.body.channel_name);
      setVideoTitle(addResponse.body.video_title);
    } else if (addResponse.body.source === 'xsocial') {
      setCreatorName(addResponse.body.channel_name);
      setVideoTitle(addResponse.body.post_title || addResponse.body.channel_nickname);
    } else if (addResponse.body.source === 'iris_tv') {
      setCreatorName('IrisTV');
      setVideoTitle(addResponse.body.video_title);
    } else if (addResponse.body.source === 'cloud_file_storage') {
      setVideoTitle(addResponse.body.video_title);
    }
    if (
      !WAITING_STATUSES.includes(addResponse.body.gpt_complete) ||
      checkIfOneDayAgo(addResponse.body.added_timestamp)
    ) {
      setEmotionDataReady(true);
      setTopicDataReady(true);
      if (addResponse.body.gpt_sentiment !== undefined) {
        setGPTSentiment(addResponse.body.gpt_sentiment);
      }
      setMetadataReady(true);

      setLoadingPercentage(99.9);
      setIsLoading(false);
      setLoadingLabel(null);
      setContentSource(addResponse.body.source);

      if (addResponse.body.highlights) {
        setHighlights(addResponse.body.highlights);
      }
      if (addResponse.body.gpt_garm_level) {
        setRiskLabel(addResponse.body.gpt_garm_level);
      }
      setGPTRiskDescription(addResponse.body.gpt_risk_desc);
      setGPTSummary(addResponse.body.gpt_summary);
      setRiskDescription(
        determineRiskDescription(
          addResponse.body.text_collect_status,
          addResponse.body.image_collect_status,
          addResponse.body.post_collect_status,
        ),
      );
    } else if (addResponse.status === 200) {
      console.log('Starting polling.');
      if (addResponse.body.gpt_complete === NO_CONTENT || addResponse.body.gpt_complete === BACKLOGGED_STATUS) {
        setRiskLabel(addResponse.body.gpt_complete);
        setIsLoading(false);
        setLoadingLabel(null);
        setLoadingPercentage(0);
        setAlertMessage(
          `Unable to access ${addResponse.body.content_type} for processing. Redirecting to gallery...`,
        );
        setShowAlert(true);
        setShowProgressBar(false);
        const id = setTimeout(() => {
          setShowAlert(false);
          setAlertMessage('');
          navigate('/');
        }, 10000);
        setTimeoutId(id);
      }
      if (
        WAITING_STATUSES.includes(addResponse.body.text_collect_status) ||
        (WAITING_STATUSES.includes(addResponse.body.image_collect_status) &&
          loadingLabel !== 'Collecting data...')
      ) {
        setLoadingLabel('Collecting data...');
      } else if (loadingLabel !== 'Processing data...') {
        setLoadingLabel('Processing data...');
      }
      if (['youtube', 'tiktok', 'instagram', 'iris_tv'].includes(addResponse.body.source)) {
        setMetadataReady(true);
      } else if (addResponse.body.source === 'article' && addResponse.body.article_title) {
        setMetadataReady(true);
      } else if (addResponse.body.source === 'xsocial' && addResponse.body.channel_name) {
        setMetadataReady(true);
      }

      const checkStatus = async () => {
        try {
          const infoResponse = await api.mbs.getVideoInfo(url);
          setProcessStatus(addResponse.body.gpt_complete);
          if (infoResponse.body.video_id) {
            setVideoId(infoResponse.body.video_id);
          }
          if (infoResponse.body.source) {
            setContentSource(infoResponse.body.source);
          }

          if (infoResponse.body.loading_percentage !== undefined) {
            setLoadingPercentage(
              infoResponse.body.loading_percentage * maximumProcessingPercentage,
            );
          } else {
            setLoadingPercentage(0);
          }
          if (['youtube', 'tiktok', 'instagram', 'iris_tv'].includes(infoResponse.body.source)) {
            setMetadataReady(true);
          } else if (infoResponse.body.source === 'article' && infoResponse.body.article_title) {
            setMetadataReady(true);
          } else if (infoResponse.body.source === 'xsocial' && infoResponse.body.channel_name) {
            setMetadataReady(true);
          }

          if (
            WAITING_STATUSES.includes(infoResponse.body.text_collect_status) ||
            (WAITING_STATUSES.includes(infoResponse.body.image_collect_status) &&
              loadingLabel !== 'Collecting data...')
          ) {
            setLoadingLabel('Collecting data...');
          } else if (loadingLabel !== 'Processing data...') {
            setLoadingLabel('Processing data...');
          }
          setContentSource(infoResponse.body.source);
          if (infoResponse.body.source === 'youtube') {
            setCreatorName(infoResponse.body.channel_name);
            setVideoTitle(infoResponse.body.video_title);
          } else if (infoResponse.body.source === 'article') {
            setCreatorName(infoResponse.body.channel_id);
            setVideoTitle(infoResponse.body.article_title);
          } else if (infoResponse.body.source === 'tiktok') {
            setCreatorName(infoResponse.body.channel_name);
            setVideoTitle(infoResponse.body.video_title);
          } else if (infoResponse.body.source === 'xsocial') {
            setCreatorName(infoResponse.body.channel_name);
            setVideoTitle(infoResponse.body.post_title || infoResponse.body.channel_nickname);
          } else if (infoResponse.body.source === 'iris_tv') {
            setCreatorName('IrisTV');
            setVideoTitle(infoResponse.body.video_title);
          } else if (addResponse.body.source === 'cloud_file_storage') {
            setVideoTitle(addResponse.body.video_title);
          }
          if (
            !WAITING_STATUSES.includes(infoResponse.body.gpt_complete) ||
            checkIfOneDayAgo(infoResponse.body.added_timestamp)
          ) {
            setRiskLabel(infoResponse.body.gpt_garm_level);
            if (infoResponse.body.gpt_complete === NO_CONTENT || infoResponse.body.gpt_complete === BACKLOGGED_STATUS) {
              setLoadingPercentage(0);
              setAlertMessage(
                `Unable to access ${infoResponse.body.content_type} for processing. Redirecting to gallery...`,
              );
              setShowAlert(true);
              setShowProgressBar(false);
              const id = setTimeout(() => {
                setShowAlert(false);
                setAlertMessage('');
                navigate('/');
              }, 10000);
              setTimeoutId(id);
            } else {
              setEmotionDataReady(true);
              setTopicDataReady(true);
              setLoadingPercentage(99.9);

              setGPTRiskDescription(infoResponse.body.gpt_risk_desc);
              setGPTSummary(infoResponse.body.gpt_summary);
              if (infoResponse.body.highlights) {
                setHighlights(infoResponse.body.highlights);
              }
              setRiskLabel(infoResponse.body.gpt_garm_level);
              console.log('Set risk label:', infoResponse.body.gpt_garm_level);
              if (infoResponse.body.gpt_sentiment !== undefined) {
                setGPTSentiment(infoResponse.body.gpt_sentiment);
              }
              setRiskDescription(
                determineRiskDescription(
                  infoResponse.body.text_collect_status,
                  infoResponse.body.image_collect_status,
                  infoResponse.body.post_collect_status,
                ),
              );
            }
            setIsLoading(false);
            setLoadingLabel(null);
          } else {
            const id = setTimeout(() => checkStatus(), 8000);
            timeoutRefs.current.push(id);
            // if (infoResponse?.body?.loading_percentage <= 0) {
            //     const id = setTimeout(() => checkStatus(), 5000);
            //     timeoutRefs.current.push(id);
            // } else if (0 < infoResponse?.body?.loading_percentage <= 0.33) {
            //     const id = setTimeout(() => checkStatus(), 15000);
            //     timeoutRefs.current.push(id);
            // } else if (0.33 < infoResponse?.body?.loading_percentage <= 0.75) {
            //     const id = setTimeout(() => checkStatus(), 6000);
            //     timeoutRefs.current.push(id);
            // }
          }
        } catch (error) {
          console.error('Failed to fetch video info:', error);
        }
      };
      setIsLoading(true);
      checkStatus();
    } else if (addResponse.status === 206 || addResponse.status === 502) {
      setIsLoading(false);
      setLoadingLabel(null);
      setLoadingPercentage(0);
      setAlertMessage(addResponse.body.message);
      setShowAlert(true);
      const id = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage('');
      }, 10000);
      setTimeoutId(id);
    } else {
      console.log('Add video was not successful:', addResponse.status);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // React router is preserving scroll position for some reason
    const currentTimeoutRefs = timeoutRefs.current;
    return () => {
      currentTimeoutRefs.forEach(clearTimeout);
    };
  }, []);

  const handleBackClick = useCallback(() => {
    setUrl('');
    navigate(fromPage, { state: { from: location.pathname } });
  }, [setUrl, navigate, fromPage, location.pathname]);

  const ensureArticleHttps = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <Box className="scorecard-background">
      {showAlert && (
        <div className="alert-message">
          {alertMessage}
          <button
            onClick={() => {
              setShowAlert(false);
              clearTimeout(timeoutId);
            }}
            className="close-button"
          >
            X
          </button>
        </div>
      )}
      <Box className="safety-info-container">
        <Box width="100%" display="flex" justifyContent="flex-start">
          <button className="back-button" onClick={handleBackClick}>
            ←
          </button>
        </Box>
        {showProgressBar && (
          <LoadingBar loadingPercentage={loadingPercentage} loadingLabel={loadingLabel} />
        )}
        {riskLabel !== NO_CONTENT && (
          <Box className="safety-info-container-inner">
            {videoTitle &&
              (source === 'article' ? (
                <a
                  href={ensureArticleHttps(videoUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-style-link"
                >
                  <h1 className="video-title">{videoTitle}</h1>
                </a>
              ) : (
                <h1 className="video-title">{videoTitle}</h1>
              ))}
            {source && videoId && <ContentEmbed source={source} embedId={videoId} url={videoUrl} />}
            <Box sx={{ filter: isLoading ? 'grayscale(1)' : 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="stretch"
                  padding="10px 0"
                >
                  <Box
                    width="calc(50% - 4px)"
                    bgcolor="white"
                    borderRadius="6px"
                    boxShadow="0 4px 2px -2px #CFD8DC"
                    display="flex"
                    padding="8px"
                    marginRight="8px"
                  >
                    <RiskGauge riskLevel={riskLabelMap ? riskLabelMap[riskLabel]?.label : ''} />
                  </Box>
                  <Box
                    width="calc(50% - 4px)"
                    bgcolor="white"
                    borderRadius="6px"
                    boxShadow="0 4px 2px -2px #CFD8DC"
                    display="flex"
                    padding="8px"
                  >
                    <SentimentIndicator
                      sentiment={sentimentLabelMap ? sentimentLabelMap[gptSentiment]?.label : ''}
                    />
                  </Box>
                </Box>
                <Box
                  bgcolor="white"
                  borderRadius="6px"
                  boxShadow="0 4px 2px -2px #CFD8DC"
                  padding="16px"
                >
                  <Typography fontSize="16px" fontWeight="600" color="#263228">
                    Risk Summary
                  </Typography>
                  <Typography fontSize="14px" color="#607D8B">
                    {gptRiskDescription && <>{gptRiskDescription}</>}
                    {!gptRiskDescription && (
                      <Box width="100%" display="flex" flexDirection="column">
                        <PlaceholderLine widthPercent={86} />
                        <PlaceholderLine widthPercent={90} />
                        <PlaceholderLine widthPercent={74} />
                      </Box>
                    )}
                  </Typography>
                  {highlights && source && (
                    <AdditionalContext videoId={videoId} highlights={highlights} source={source} />
                  )}
                </Box>
              </Box>
              <div>
                <MetadataTagDisplay
                  emotionData={emotionData}
                  topics={topicData}
                  keywordData={metadata}
                  personas={personas}
                />
              </div>
              {(metadata || NEVER_METADATA_SOURCE_TYPES.includes(source)) && (
                <Box marginBottom="8px">
                  <MetadataSummary
                    metadata={metadata}
                    summary={gptSummary}
                    topics={topicData}
                    emotionData={emotionData}
                    source={source}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VideoInfo;

const PlaceholderLine = styled(Box)<{ widthPercent: number }>`
  width: ${(props) => props.widthPercent}%;
  min-width: ${(props) => props.widthPercent}%;
  margin-bottom: 9px;
  height: 11px;
  background-color: #d9d9d9;
`;
