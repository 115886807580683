import React from 'react';

export const transformPercentageMetricsData = (data, labels, contentCount, colorDict) => {
  if (!data || !labels || data.length !== labels.length) {
    return [];
  }

  return data.map((value, index) => {
    const guide = {
      'Metric Name': labels[index],
      'Value Type': 'Percentage',
    };

    if (colorDict[labels[index]]) {
      guide.Color = colorDict[labels[index]];
    }

    return {
      guide,
      value: value / contentCount,
    };
  });
};
export const transformAndCombineRiskData = (data, labels) => {
  if (!data || !labels) {
    console.error('Data or labels are undefined:', { data, labels });
    return { data: [], labels: [] };
  }

  let lowRiskIndex = labels.indexOf('Low');
  let noRiskIndex = labels.indexOf('No');

  // Combine "No Risk" into "Low Risk"
  if (noRiskIndex !== -1) {
    if (lowRiskIndex !== -1) {
      data[lowRiskIndex] += data[noRiskIndex];
    } else {
      labels.push('Low Risk');
      data.push(data[noRiskIndex]);
    }
    labels.splice(noRiskIndex, 1);
    data.splice(noRiskIndex, 1);
  }

  console.log('Transformed risk data:', { data, labels });
  return { data, labels };
};

export const transformCountMetricsData = (data, labels) => {
  if (!data || !labels || data.length !== labels.length) {
    console.error('Data or labels are invalid:', { data, labels });
    return [];
  }

  return data.map((value, index) => {
    return {
      guide: {
        'Metric Name': labels[index],
        'Value Type': 'Count',
      },
      value: value,
    };
  });
};
