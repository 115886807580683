import React from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';

export function DividedStats({ stats }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2E2E2E',
        overflow: 'hidden',
        width: '100%',
        marginBottom: 2,
      }}
    >
      <Grid width="100%" container spacing={2} alignItems="center" justifyContent="center">
        {stats.map((stat, index) => (
          <React.Fragment key={index}>
            <Grid item xs={index % 2 === 0 ? 5 : 5} sm={5} md={3}>
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography
                  align="center"
                  sx={{ fontSize: { xs: '20px', sm: '3rem', md: '4rem' }, overflow: 'hidden' }}
                >
                  {stat.value}
                </Typography>
                <Typography
                  align="center"
                  sx={{ fontSize: { xs: '10px', sm: '1rem', md: '1.25rem' }, overflow: 'hidden' }}
                >
                  {stat.label}
                </Typography>
              </Box>
            </Grid>
            {index < stats.length - 1 && (
              <Grid
                item
                xs={2}
                sm={2}
                md={1}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: '5rem',
                    borderRightWidth: 2,
                    borderColor: 'text.primary',
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}
