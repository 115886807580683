import styled from '@emotion/styled';
import Page from 'Components/Page';
import React from 'react';

export default function PrivacyPage() {
  const ContactEmail = () => {
    return <a href="mailto:contact@themobian.com">contact@themobian.com</a>
  };

  return (
    <Page greyBackground>
      <CentredLayout>
        <Title>Mobian Privacy Policy</Title>
        <p>Effective Date: Sep 22, 2023</p>
        <h2>1. Introduction</h2>
        <p>
          Please read this Privacy Policy to learn how we treat your personal data. By using or
          accessing our Services in any manner, you acknowledge that you accept the practices and
          policies outlined below, and you hereby consent that we will collect, use and share your
          information as described in this Privacy Policy. This Privacy Policy does not cover the
          practices of companies we don’t own or control or people we don’t manage.
        </p>
        <h2>2. Information We May Collect</h2>
        <p>We may collect the following categories of personal information:</p>
        <ul>
          <li>Identifiers (e.g., name, email address, phone number)</li>
            <li>
            Personal information categories listed in the California Customer Records statute (e.g.,
            address, payment information)
          </li>
            <li>Commercial information (e.g., products or services purchased)</li>
            <li>
            Internet or other electronic network activity information (e.g., browsing history, device
            information)
          </li>
            <li>Geolocation data</li>
            <li>Inferences drawn from the above information</li>
            <li>Automatically or when you directly provide information</li>
        </ul>
        <h2>3. How We May Use Your Information</h2>
        <p>We may use your personal information for the following purposes:</p>
        <ul>
          <li>To provide products or services you request and engage with</li>
            <li>To process and complete transactions</li>
            <li>To communicate with you and respond to your inquiries</li>
            <li>To personalize and customize your experience</li>
            <li>To improve our products and services</li>
            <li>To comply with legal obligations</li>
            <li>For marketing and advertising purposes (with your consent, where required by law)</li>
        </ul>
        <h2>4. Sharing Your Information</h2>
        <p>We may share your personal information with:</p>
        <ul>
          <li>Service providers who assist us in delivering our products and services</li>
          <li>Affiliates for marketing and operational purposes</li>
          <li>
            Third parties for legal reasons, such as responding to legal requests or preventing fraud
          </li>
          <li>Other parties when you give us your consent to do so</li>
          <li>Business transfer, ex: merger, acquisition</li>
        </ul>
        <h2>5. Your Rights</h2>
        <p>Subject to applicable laws, you have the following rights:</p>
        <ul>
          <li>Right to access your personal information</li>
          <li>Right to rectify inaccuracies in your personal information</li>
          <li>Right to delete your personal information</li>
          <li>Right to restrict the processing of your personal information</li>
          <li>Right to data portability</li>
          <li>Right to object to the processing of your personal information</li>
          <li>Right to opt-out of the sale of personal information (if applicable)</li>
        </ul>
        <p>
          To exercise these rights, as applicable, please contact us using the information provided
          below.
        </p>
        <h2>6. Data That is Not Personal Data</h2>
        <p>
          We may create aggregated, de-identified or anonymized data from the Personal Data we
          collect, including by removing information that makes the data personally identifiable to a
          particular user. We may use such aggregated, de-identified or anonymized data and share it
          with third parties for our lawful business purposes, including to analyze, build and improve
          the Services and promote our business, provided that we will not share such data in a manner
          that could identify you.
        </p>
        <h2>7. Data Security &amp; Retention</h2>
        <p>
          We implement reasonable security measures to protect your personal information from
          unauthorized access and disclosure. However, no method of data transmission over the
          internet is entirely secure, and we cannot guarantee the security of your information.
        </p>
        <p>
          We retain Personal Data about you for as long as necessary to provide you with our Services,
          to comply with legal obligations, or as long as permitted by applicable law.
        </p>
        <h2>8. Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in our practices or for
          other operational, legal, or regulatory reasons. The most recent version will be posted with
          the effective date at the top.
        </p>
        <h2>9. Personal Data of Children</h2>
        <p>
          We do not knowingly collect or solicit Personal Data about children under 16 years of age;
          if you are a child under the age of 16, please do not attempt to register for or otherwise
          use the Services or send us any Personal Data. If we learn we have collected Personal Data
          from a child under 16 years of age, we will delete that information as quickly as possible.
          If you believe that a child under 16 years of age may have provided Personal Data to us,
          please contact us at <ContactEmail />.
        </p>
        <h2>10. State Law Privacy Rights</h2>
        <h3>California Resident Rights</h3>
        <p>
          Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to
          contact us to prevent disclosure of Personal Data to third parties for such third parties’
          direct marketing purposes; in order to submit such a request, please contact us at{' '}
          <ContactEmail />.
        </p>
        <h3>Nevada Resident Rights</h3>
        <p>
          If you are a resident of Nevada, you have the right to opt-out of the sale of certain
          Personal Data to third parties who intend to license or sell that Personal Data. You can
          exercise this right by contacting us at <ContactEmail /> with the subject line “Nevada
          Do Not Sell Request” and providing us with your name. Please note that the Company does not
          sell Personal Data.
        </p>
        <h2>11. Contact Information</h2>
        <p>
          If you have any questions or comments about this Privacy Policy, the ways in which we
          collect and use your Personal Data or your choices and rights regarding such collection and
          use, please do not hesitate to contact us at <ContactEmail />.
        </p>
      </CentredLayout>
    </Page>
  );
}

const Title = styled.h1`
  text-align: center;
`;

const CentredLayout = styled.div`
  padding: 2rem 0 4rem;
  text-align: left;
  max-width: 1280px;
  width: 100%;
`;