export const sourceOptions = [
  { value: 'tiktok', label: 'TT' },
  { value: 'youtube', label: 'YT' },
  { value: 'article', label: 'Web' },
];

export const garmRiskOptions = [
  { value: '1', label: 'Low' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'High' },
];

export const sentimentOptions = [
  { value: '0', label: 'Positive' },
  { value: '2', label: 'Neutral' },
  { value: '1', label: 'Negative' },
];

export const emotionOptions = [
  { label: 'Love', value: 'love' },
  {
    label: 'Joy',
    value: 'joy,happy,happiness',
  },
  { label: 'Surprise', value: 'surprise,surprising' },
  { label: 'Anger', value: 'anger,angry,angered' },
  { label: 'Sadness', value: 'sadness,sad' },
  { label: 'Fear', value: 'fear,fearful,feared' },
];

export const genreOptions = [
  { label: 'Action', value: 'action' },
  { label: 'Adventure', value: 'adventure' },
  { label: 'Animation', value: 'animation, animated' },
  { label: 'Biography', value: 'biography' },
  { label: 'Comedy', value: 'comedy, satire' },
  { label: 'Crime', value: 'crime' },
  { label: 'Documentary', value: 'documentary' },
  { label: 'Drama', value: 'drama' },
  { label: 'Education', value: 'education,educational,educate,learning,teach,learn' },
  { label: 'Family', value: 'family,family friendly,kid,kids' },
  { label: 'Fantasy', value: 'fantasy' },
  { label: 'Historical', value: 'historical, history' },
  { label: 'Horror', value: 'horror' },
  {
    label: 'Lifestyle',
    value: 'lifestyle, editorial, fashion, food, culinary, health, wellness,diy',
  },
  { label: 'Musical', value: 'musical,music' },
  { label: 'Mystery', value: 'mystery' },
  { label: 'News', value: 'news, breaking news' },
  { label: 'Politics', value: 'politics,political,political commentary' },
  { label: 'Romance', value: 'romance, romantic' },
  { label: 'Science Fiction', value: 'science fiction, scifi, sci-fi' },
  { label: 'Sports', value: 'sport,sports,football,soccer,basketball,baseball' },
  { label: 'Thriller', value: 'thriller, thrill' },
  { label: 'War', value: 'war,military' },
  { label: 'Western', value: 'western' },
];
