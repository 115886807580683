import React from 'react';

import Page from '../Components/Page';
import HomeGallery from '../Components/Gallery/HomeGallery';

const GalleryPage = () => {
  return (
    <Page showSearch>
      <HomeGallery />
    </Page>
  );
};

export default GalleryPage;
