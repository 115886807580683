import React, { useState, useEffect } from 'react';

import LoadingBar from 'Components/Scorecard/LoadingBar';

interface Props {
  time: number; // Time in seconds
  label: string;
}

const TimedLoadingBar: React.FC<Props> = ({ time, label }) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const interval = 50; // Update every 50ms for smooth animation
    const steps = (time * 1000) / interval;
    const incrementPerStep = 95 / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      if (currentStep < steps) {
        setLoadingPercentage((prevPercentage) => {
          const newPercentage = prevPercentage + incrementPerStep;
          return newPercentage > 95 ? 95 : newPercentage;
        });
        currentStep++;
      } else {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [time]);

  return <LoadingBar loadingPercentage={loadingPercentage} margin="0" loadingLabel={label} />;
};

export default TimedLoadingBar;
